import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

@Component({
	selector: "app-image-selector",
	templateUrl: "./image-selector.component.html",
	styleUrls: ["./image-selector.component.scss"],
})
export class ImageSelectorComponent {
	croppedImage: string = "";
	imageChangedEvent: Event;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { inputImageSrc: any },
		public dialog: MatDialogRef<ImageSelectorComponent>,
	) {}

	closeModalImagen() {
		this.dialog.close();
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	fileChangeEvent(event: Event): void {
		const inputElement = event.target as HTMLInputElement;

		if (inputElement.value.length > 0) {
			this.imageChangedEvent = event;
		}
	}

	confirmarSeleccion() {
		this.dialog.close(this.croppedImage);
	}

	// --------------------- MODIFICACION DE LA IMAGEN ---------------------

	canvasRotation = 0;
	rotation?: number;
	translateH = 0;
	translateV = 0;
	scale = 1;
	transform: ImageTransform = {};
	imageURL?: string;
	loading = false;

	rotateLeft() {
		this.loading = true;
		setTimeout(() => {
			// Use timeout because rotating image is a heavy operation and will block the ui thread
			this.canvasRotation--;
			this.flipAfterRotate();
		});
	}

	rotateRight() {
		this.loading = true;
		setTimeout(() => {
			this.canvasRotation++;
			this.flipAfterRotate();
		});
	}

	private flipAfterRotate() {
		const flippedH = this.transform.flipH;
		const flippedV = this.transform.flipV;
		this.transform = {
			...this.transform,
			flipH: flippedV,
			flipV: flippedH,
		};
	}

	flipHorizontal() {
		this.transform = {
			...this.transform,
			flipH: !this.transform.flipH,
		};
	}

	flipVertical() {
		this.transform = {
			...this.transform,
			flipV: !this.transform.flipV,
		};
	}

	resetImage() {
		this.scale = 1;
		this.rotation = 0;
		this.canvasRotation = 0;
	}

	zoomOut() {
		this.scale -= 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	zoomIn() {
		this.scale += 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	updateRotation() {
		this.transform = {
			...this.transform,
			rotate: this.rotation,
		};
	}
}
