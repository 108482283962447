<div fxLayout="column" class="h-100-p gap-1">
	<h2 class="m-0" mat-dialog-title>Cambiar imagen</h2>

	<mat-dialog-content class="h-100-p">
		<!-- ESTADO INICIAL -->
		<div *ngIf="!imageChangedEvent" fxLayout="column" fxLayoutAlign="center center" class="gap-1 h-100-p">
			<h3 class="m-0">{{ data.inputImageSrc ? "Imagen actual" : "Seleccione una imagen" }}</h3>
			<div class="image-wrapper clickable">
				<img *ngIf="data.inputImageSrc" [src]="data.inputImageSrc" style="max-width: 400px" alt="Imagen actual"
					(click)="fileImagenPerfil.click()" />
			</div>
		</div>

		<!-- TRAS SELECCIONAR UNA IMAGEN -->
		<div *ngIf="imageChangedEvent" fxLayout="row" fxLayoutAlign="center center" class="px-48 h-100-p"
			style="gap: 4em">
			<div class="image-wrapper">
				<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
					[canvasRotation]="canvasRotation" [(transform)]="transform" [aspectRatio]="1 / 1" format="png"
					output="base64" (imageCropped)="imageCropped($event)"></image-cropper>
			</div>

			<div fxLayout="column" fxLayoutAlign="center center" class="gap-1">
				<h3 class="m-0">Previsualización</h3>

				<div class="gap-1" fxLayout="row" style="position: relative">
					<div class="image-wrapper">
						<img [src]="croppedImage" style="max-width: 200px; max-height: 200px"
							alt="Previsualización de la imagen" />
					</div>

					<div id="image-toolbox" fxLayout="column" fxLayoutAlign="start center" class="box gap-1">
						<button mat-icon-button (click)="rotateLeft()">
							<mat-icon>rotate_left</mat-icon>
						</button>

						<button mat-icon-button (click)="rotateRight()">
							<mat-icon>rotate_right</mat-icon>
						</button>
						<button mat-icon-button (click)="flipHorizontal()">
							<mat-icon>flip</mat-icon>
						</button>
						<button mat-icon-button (click)="flipVertical()">
							<mat-icon style="rotate: 90deg">flip</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
		<div>
			<input type="file" (change)="fileChangeEvent($event)" style="display: none" #fileImagenPerfil />
			<button mat-raised-button color="primary" (click)="fileImagenPerfil.click()">{{ "person_card.select_image" |
				translate }}</button>
		</div>

		<div>
			<button mat-button (click)="closeModalImagen()">Cancelar</button>
			<button mat-button [disabled]="!imageChangedEvent" (click)="confirmarSeleccion()">{{ "general.button.accept"
				| translate }}</button>
		</div>
	</div>
</div>